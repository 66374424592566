export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
        "processingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executing"])},
        "processingHints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a background task executing, just wait a moment."])},
        "succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installed history successfully before."])},
        "viewLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Task Logs"])},
        "taskFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task is already completed"])},
        "closeWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Force to close this dialog window."])},
        "taskInCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task in Running"])},
        "viewLastSuccessLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last success log"])},
        "viewLastLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last log"])}
      },
      "zh": {
        "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执 行"])},
        "processingTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务执行中"])},
        "processingHints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前有后台任务正在执行，请耐心等待。"])},
        "viewLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看任务日志"])},
        "taskFinished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务已结束"])},
        "closeWindow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制关闭此对话框"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再次尝试执行任务"])},
        "succeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经成功执行任务"])},
        "confirmToExecute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行任务"])},
        "taskInCurrent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前有任务正在执行"])},
        "viewLastSuccessLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后成功日志"])},
        "viewLastLog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后日志"])}
      }
    }
  })
}
